@media screen and (min-width: 640px) {
    .desktop {
        display: flex !important;
    }

    .toggleMenu {
		display: none;
	}

	.dropdown {
		display: none;
	}
}

/* wrapper for entire navbar */
.bar {
    /* transition: color 330ms ease-in-out; */
    color: hsl(var(--nextui-foreground));
    height: 100%;
}

/* input:checked + .bar {
    color: white;
} */

/* shared button styling */
.btn {
    /* hover animation */
    transition-duration: .25s;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    opacity: 1;
    
    /* outline-none */
    -webkit-tap-highlight-color: transparent;
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.btn:hover {
    opacity: .8;
}

/* dropdown menu for mobile phones */
.dropdown {
    position: absolute;
    padding: 0 2rem;
    top: 0;
    bottom: 100vh;
    left: 0;
    right: 0;
    

    display: flex !important;
    flex-direction: column;
    gap: .5rem;

    z-index: -1;
    overflow: hidden;
    
    transition: bottom 400ms ease-in-out;
    background-color: hsl(var(--nextui-card));
}

input:checked + .bar .dropdown {
    bottom: 0;
}

.dropdown::before,
.dropdown::after {
	content: "";
	display: table;
	height: 4rem;
}

/* dropdown menu items */
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.item {
    opacity: 0;
    transition:
        transform .2s ease-in, 
        opacity .15s ease-in;
    transform: translateY(-8px);
}

input:checked ~ .bar .item {
    opacity: 1;
    transform: translateY(0);
    transition:
        transform .2s ease-out, 
        opacity .4s ease-in;
}

/* */
.spacer {
    flex-grow: 1;
} 

/* phone numbers at bottom of dropdown menu */
.contactInfo {
    margin-top: 1rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    transition-delay: 0ms;
}

input:checked ~ .bar .contactInfo {
    transition-delay: 425ms;
}

.contactCall {
    display: block;
    text-align: center;
}

/* Hamburger menu */
.menuBtn {
    justify-content: center;
    justify-items: center;
}

.line {
    transition: 
        stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

input:checked ~ .bar .line {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}